<template>
    <div>
    <v-data-table
        :items="items"
        class="elevation-1"
        :headers="sortedHeaders"
    >

        <template v-slot:item.files="{ item }">
           <div  v-if="item.files && item.files.file_name"> {{item.files.file_name}}</div>
        </template>
        <template v-slot:item.recipients="{ item }">
            <AdminList :admin="item.recipients" />
        </template>

        <template v-slot:item.auditors="{ item }">
            <AdminList :admin="item.auditors" />
        </template>
        <template v-slot:item.aligners="{ item }">
            <AdminList :admin="item.aligners" />
        </template>
        <template v-slot:item.responsibles="{ item }">
            <AdminList :admin="item.responsibles" />
        </template>
        <template v-slot:item.is_urgent="{ item }">
            <div>{{ item.is_urgent ? $t('yes') : $t('no')}}</div>
        </template>
        <template v-slot:item.dangerous_cargo="{ item }">
            <div>{{ item.dangerous_cargo ? $t('yes') : $t('no')}}</div>
        </template>
        <template v-slot:item.stackable_cargo="{ item }">
            <div>{{ item.stackable_cargo ? $t('yes') : $t('no')}}</div>
        </template>
        <template v-slot:item.insurance="{ item }">
            <div>{{ item.insurance ? $t('yes') : $t('no')}}</div>
        </template>
        <template v-slot:item.phones="{ item, index }">
            <div class="phone">
                <div>
                    <div v-if="item.phones && item.phones.length > 1" class="triangle_right cursor-pointer" :id="'triangle_'+index" @click="showPhone(index)"></div>
                    <div v-if="item.phones && typeof (item.phones[0]) !== 'undefined' && typeof (item.phones[0].phone) !== 'undefined'" class="phone_number" :class="item.phones.length === 1 ? 'indent_phone': ''">
                        {{ formatPhoneNumber(item.phones[0].phone ?? '') }}
                        <span>{{ item.phones[0].field }}</span>
                    </div>
                </div>
                <div class="display_none phone_pad" :class="'triangle_all_'+index"
                     v-if="item.phones">
                    <div
                        v-for="(data, i) in item.phones.slice(1)" :key="'dd_'+index+i"
                    >
                        {{ formatPhoneNumber(data.phone ?? '') }}
                        <span v-if="data.field !== 'null'">{{ data.field }}</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:item.emails="{ item, index }">
            <span v-if="item.emails && item.emails.length" v-html="item.emails.join('<br />')"></span>
        </template>
        <template v-slot:item.addresses="{ item, index }">
            <span>
                {{item.addresses ? item.addresses[0].address : ''}}
            </span>
        </template>
    </v-data-table>
    </div>
</template>
<script>

import AdminList from '@/components/AdminList.vue';

export default {
    name: "ReportDataTable",
    components: {
        AdminList
    },
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    computed: {
        sortedHeaders() {
            console.log(this.headers)
            // Сортируем заголовки по полю `sort`
            return [...this.headers]
                .sort((a, b) => a.sort - b.sort);
        },
    },
    methods: {
        showPhone(index) {
            const element = this.$el.querySelector('#triangle_' + index);
            element.classList.toggle('triangle_down');
            const element_all = this.$el.querySelector('.triangle_all_' + index);


            const hasClass = element_all.classList.contains('display_none');
            if (hasClass) {
                element_all.classList.remove('display_none');
                element_all.style.display = 'block';
            } else {
                element_all.classList.add('display_none');
                element_all.style.display = 'none';
            }
        }
    }
};
</script>

<style scoped>
/* Стилизация для таблицы и других элементов */
</style>
