    import { Doughnut } from 'vue-chartjs'
    import ChartDataLabels from 'chartjs-plugin-datalabels'
    import Chart from 'chart.js'

    // Регистрируем плагин
    Chart.plugins.register(ChartDataLabels)

    export default {
        extends: Doughnut,
        props: {
            chartData: {
                type: String,
                default: null
            },
            options: {
                type: Object,
                default() {
                    return {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom',
                            align: 'center',
                            labels: {
                                fontFamily: "'Stem  ', sans-serif",
                                fontSize: 12,

                                padding: 25
                            }
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                clamp: true,
                                align: 'center',
                                anchor: 'center',

                                font: {
                                    size: 14,
                                    family: "'Stem', sans-serif",
                                    weight: 'bold'
                                },
                                color: '#000000',
                                padding: 20
                            },

                        }
                    };
                }
            }
        },
        watch: {
            chartData(val) {
                const parsedData = JSON.parse(val);
                this.renderChart(parsedData, this.options)
            },
        },
        mounted() {
            const parsedData = JSON.parse(this.chartData);
            this.renderChart(parsedData, this.options);
        }
    }
