<template>


    <v-card
        class="mx-auto"
    >


        <v-card-text    >
            <v-row style="height: 100px !important;" >
                <v-col cols="12" class="text-center align-content-center" style="height: 100px !important; background: #f9f9f9; font-size: 24px" v-if="table && table[0]">{{table[0].count}}</v-col>
            </v-row>
        </v-card-text>


    </v-card>



</template>
<script>


export default {
    name: "ReportBlockCount",
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            table: [],
        }
    },
    mounted() {
        this.getTable();
    },
    methods: {
        getTable(){
            this.table = this.items.map(item => {
                // Ищем текстовые поля и числовые поля
                const title = Object.keys(item).find(key => typeof item[key] === 'string');
                const count = Object.keys(item).find(key => typeof item[key] === 'number');

                return {
                    title: item[title],  // сохраняем текстовое значение
                    count: item[count]    // сохраняем числовое значение
                };
            });
        }
    }
};
</script>

<style scoped>
/* Стилизация для таблицы и других элементов */
</style>
