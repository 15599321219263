<template>
    <div>
        <div v-if="admin && admin.length === 1 && admin[0] && admin[0].name">
            <v-list subheader two-line class="background_none py-0">
                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                    <v-list-item-avatar size="36" class="my-0 py-0 cursor-pointer">
                        <img v-if="admin[0].photo !== '/img/avatar.png'" :src="admin[0].photo" :alt="admin[0].name">
                        <v-icon v-else color="primary" size="22">
                            ${{ 'settingsIcon' }}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0 title_subtitle">
                        <v-list-item-title v-text="admin[0].name"></v-list-item-title>
                        <v-list-item-subtitle v-text="formatPhoneNumber(admin[0].phone)"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

        <div v-if="admin && admin.length > 1">
            <div class="avatars cursor-pointer">
                <span class="avatar" v-for="(person, index) in admin" :key="index">
                    <v-avatar size="36">
                        <img v-if="person.photo !== '/img/avatar.png'" :title="person.name" class="" :src="person.photo" :alt="person.name">
                        <img v-else :title="person.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="person.name">
                    </v-avatar>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        admin: {
            type: Array,
            required: true
        }
    },
};
</script>

<style scoped>
/* Ваши стили для компонента */
</style>
