<template>

        <v-card
            class="mx-auto pb-5"
        >


            <v-card-text    >
                <v-row  >
                    <v-col cols="12" >
                        <v-list nav>
                            <v-list-item
                                v-for="(item, index ) in table"
                                :key="'' + index"
                                style="background: #f9f9f9"
                                class="mb-3"
                            >

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    {{item.count}}
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                    </v-col>
                </v-row>
            </v-card-text>


        </v-card>

</template>
<script>


export default {
    name: "ReportTableOneColumn",
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            table: [],
        }
    },
    mounted() {
        this.getTable();
    },
    methods: {
        getTable(){
            this.table = this.items.map(item => {
                // Ищем текстовые поля и числовые поля
                const title = Object.keys(item).find(key => typeof item[key] === 'string');
                const count = Object.keys(item).find(key => typeof item[key] === 'number');

                return {
                    title: item[title],  // сохраняем текстовое значение
                    count: item[count]    // сохраняем числовое значение
                };
            });
        }
    }
};
</script>

<style scoped>
/* Стилизация для таблицы и других элементов */
</style>
